import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import mainFrame from '@/frameworks/mainFrame.vue';
import Home from '@/views/Home.vue';

const routes: RouteRecordRaw[] = [
  // {
  //   path: '',
  //   component: mainFrame,
  //   children: [
  //     {
  //       path: '',
  //       name: 'Home',
  //       component: Home,
  //     },
  //   ],
  // },
  {
    path: '',
    redirect: '/upgrading',
  },
  {
    path: '/upgrading',
    name: 'Upgrading',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Upgrading" */ '../views/Upgrading.vue')
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
