<template>
  <router-view/>
</template>

<style lang="scss">
//字体导入
@font-face{
  font-family: "PingFang SC";
  src:url('./assets/fonts/PingFang.ttf');
}
@font-face{
  font-family: "YouSheBiaoTiHei";
  src:url('./assets/fonts/YouSheBiaoTiHei.ttf');
}
//样式重置
html, body {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: #409EFF;
  &:active {
    color: #F56C6C;
  }
}
button, .button {
  --button-height: auto;
  height: var(--button-height);
  line-height: var(--button-height);
  border: 0;
  cursor: pointer;
  font-family: "PingFang SC", sans-serif;
  text-align: center;
  display: table;
  align-items: center;
  &:active {
    border: 2px inset rgb(118, 118, 118);
    border-image: initial;
  }
}
// 公共样式
body {
  font-family: PingFang SC, sans-serif;
  min-width: 1280px;
}
.section-content {
  width: 1200px;
  margin: 0 auto;
}
.section-title {
  font-family: YouSheBiaoTiHei, sans-serif;
  text-transform: uppercase;
  overflow: hidden;
  height: 153px;

  .section-title-bg {
    position: absolute;
    z-index: -1;
    text-indent: -267px;
    font-size: 118px;
    font-weight: 400;
    color: #eee;
  }
  .section-title-zh {
    margin-top: 39px;
    font-size: 39px;
    font-weight: 400;
    color: #000;
  }
  .section-title-en {
    position: relative;
    margin-top: 3px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    text-indent: 23px;

    &:before {
      content: '';
      box-sizing: border-box;
      width: 17px;
      height: 17px;
      background: url("./assets/icons/circle-medium.png");
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
}
</style>
